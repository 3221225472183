import * as React from "react"
import Layout from '../components/Layout'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useInView } from 'react-intersection-observer';

import './about.scss';

import chain090Svg from '../assets/illustratlion/chain_090.svg'
import chain180Svg from '../assets/illustratlion/chain_180.svg'
import legoSvg_2 from '../assets/illustratlion/lego_2.svg'



import universeSvg from '../assets/illustratlion/universe.svg'
import legoSvg from '../assets/illustratlion/lego.svg'
import movingBlockSvg from '../assets/illustratlion/moving_block.svg'
import blockchainSvg from '../assets/illustratlion/blockchain.svg'
import peopleSvg from '../assets/illustratlion/people.svg'

import { ioOption, fadeIn } from "../utils"

// IMG SOURCE
const universeImg = "../assets/illustratlion/universe.png"
const chain180Img = "../assets/illustratlion/chain_180.png"
const chain090Img = "../assets/illustratlion/chain_090.png"
const lego01Img = "../assets/illustratlion/lego.png"
const lego02Img = "../assets/illustratlion/lego_2.png"
const dragAndDropImg = "../assets/illustratlion/drag_and_drop_090.png"
const blockchainImg = "../assets/illustratlion/blockchain.png"
const peopleImg = "../assets/illustratlion/people.png"

const hightLights = [{
  title: "Decentralized",
  text: "If the power to create for Web3 lies in the hands of the few, we will continue to uphold a system of centralized power in finance. Eradicating technical hurdles ensures the creation process itself is decentralized.",
},{
  title: "Efficient",
  text: "Talent is evenly distributed, but access is not. Include gives access to anyone with a computer and internet connection to become a blockchain creator.",
},{
  title: "Scalable",
  text: "With visual creation, we see the potential for DeFi to grow from the current thousands of Dapps serving millions of early adopters, to millions of Dapps serving billions of mass consumers.",
}]

const users = [{
  title: "For Creators",
  text: "Build your own blockchain Dapp for commerce, social good or self expression.",
},{
  title: "For Developers",
  text: "Save time and effort by reusing and adapting Dapp components.",
},{
  title: "For Users",
  text: "Elevate both work and play on the blockchain with more Dapps.",
}]






const Box = ({title, text, index}) => {
  const [ refBoxTitle, inViewBoxTitle, entryBoxTitle ] = useInView(ioOption);
  const [ refBoxText, inViewBoxText, entryBoxText ] = useInView(ioOption);


  return (
    <div className={`box box-${index}`}>
      <div 
        ref={refBoxTitle} 
        className={`title ${fadeIn(inViewBoxTitle)}`}
      >
        {title}
      </div>
      <p 
        ref={refBoxText} 
        className={`text ${fadeIn(inViewBoxText)}`}
      >
        {text}
      </p>
    </div>
  )
}




const AboutPage = () => {
  const [ refHeroImg, inViewHeroImg, entryHeroImg ] = useInView(ioOption);
  const [ refHeroImgMobile, inViewHeroImgMobile, entryHeroImgMobile ] = useInView(ioOption);
  const [ refHeroContent, inViewHeroContent, entryHeroContent ] = useInView(ioOption);
  const [ refMissionImg, inViewMissionImg, entryMissionImg ] = useInView(ioOption);
  const [ refMissionImgMobile, inViewMissionImgMobile, entryMissionImgMobile ] = useInView(ioOption);
  const [ refMissionContent, inViewMissionContent, entryMissionContent ] = useInView(ioOption);
  const [ refTechImg, inViewTechImg, entryTechImg ] = useInView(ioOption);
  const [ refTechContent, inViewTechContent, entryTechContent ] = useInView(ioOption);
  const [ refValueImg, inViewValueImg, entryValueImg ] = useInView(ioOption);
  const [ refValueContent, inViewValueContent, entryValueContent ] = useInView(ioOption);
  const [ refUserImg, inViewUserImg, entryUserImg ] = useInView(ioOption);
  const [ refUserImgMobile, inViewUserImgMobile, entryUserImgMobile ] = useInView(ioOption);


  return (
    <Layout
      title="Include | About"
      pageClassName="page-about"
    > 
      {/* HERO */}
      <section className="page-about__section-hero">
        <div className="page-about__section-hero__container">
          <div ref={refHeroContent} className={`page-about__section-hero__container__content ${fadeIn(inViewHeroContent)}`}>
            <h1 className="page-title">About Include</h1>
            <p className="page-title-tag">The world’s first universal language of blockchains</p>
          </div>
          <div className="img-container--desktop" ref={refHeroImg}>
            <StaticImage 
              className={`page-about__section-hero__container__img section-img ${fadeIn(inViewHeroImg)}`}
              src={ universeImg }
              objectFit="contain"
              layout="fullWidth"
              alt="" 
            />
          </div>
          <div className="img-container--mobile" ref={refHeroImgMobile}>
            <StaticImage 
              className={`page-about__section-hero__container__img--mobile ${fadeIn(inViewHeroImgMobile)}`}
              src={ chain180Img }
              objectFit="contain"
              layout="fixed"
              alt="" 
            />
          </div>


          {/* <img
            ref={refHeroImg}
            className={`page-about__section-hero__container__img section-img ${fadeIn(inViewHeroImg)}`}
            src={ universeSvg } 
            alt="" 
          /> */}
          {/* <img
            ref={refHeroImgMobile}
            className={`page-about__section-hero__container__img--mobile ${fadeIn(inViewHeroImgMobile)}`} 
            src={ chain180Svg } 
            alt=""  
          /> */}
        </div>
      </section>


      <section className="page-about__section-us">
        <div className="page-about__section-us__container">
          {/* MISSION */}
          <div className="page-about__section-us__container__section image-right mission">
            <div className={`${fadeIn(inViewMissionImg)}`}ref={refMissionImg}>
              <StaticImage 
                className={`page-about__section-us__container__section__img--desktop image`}
                src={ lego01Img }
                objectFit="contain"
                alt="" 
              />
            </div>
            {/* <img
              ref={refMissionImg}
              className={`page-about__section-us__container__section__img--desktop image ${fadeIn(inViewMissionImg)}`}
              src={ legoSvg } 
              alt="" 
            /> */}
            <div className={`${fadeIn(inViewMissionImgMobile)}`} ref={refMissionImgMobile}>
              <StaticImage 
                className={`page-about__section-us__container__section__img--mobile image`} 
                src={ lego02Img }
                objectFit="contain"
                layout="fixed"
                alt="" 
              />
            </div>
            {/* <img
              ref={refMissionImgMobile}
              className={`page-about__section-us__container__section__img--mobile ${fadeIn(inViewMissionImgMobile)}`} 
              src={ legoSvg_2 }
              alt=""
            /> */}
            <div 
              ref={refMissionContent}
              className={`page-about__section-us__container__section__content content ${fadeIn(inViewMissionContent)}`}
            >
              <h1 className="title">Our Mission</h1>
              <p className="text">
              Include is a low-code platform on a mission to democratize Web3 and blockchain wealth creation by empowering millions of creators serving billions of consumers. Through our intuitive UX, we want to make blockchain creation more <strong>accessible, decentralized and scalable</strong>. By generating multichain contracts across different languages like Solidity, Vyper and Rust, we aspire to serve as the de facto <strong>Universal Language of Blockchains</strong>.
              </p>
            </div>
          </div>

          {/* TECHNOLOGY */}
          <div className="page-about__section-us__container__section image-left tech">
            <div className={`${fadeIn(inViewTechImg)}`} ref={refTechImg}>
              <StaticImage 
                className={`page-about__section-us__container__section__img image`}
                src={ dragAndDropImg }
                objectFit="contain"
                alt="" 
              />
            </div>
            {/* <img
              ref={refTechImg}
              className={`page-about__section-us__container__section__img image ${fadeIn(inViewTechImg)}`}
              src={ movingBlockSvg } 
              alt="" 
            /> */}
            <div 
              ref={refTechContent}
              className={`page-about__section-us__container__section__content content ${fadeIn(inViewTechContent)}`}
            >
              <h1 className="title">Our Technology</h1>
              <p className="text">
                Include’s core technology translates visual projects into smart contracts without the need to write code. Creators build by using drag-and-drop visual blocks and entering data. Our algorithms check program validity, generate corresponding smart contracts, and support multi-chain deployment.
              </p>
            </div>
          </div>

          {/* VALUES */}
          <div className="page-about__section-us__container__section image-right values">
            <div className={`img-wrapper ${fadeIn(inViewValueImg)}`} ref={refValueImg}>
              <StaticImage 
                className={`page-about__section-us__container__section__img image`}
                src={ blockchainImg }
                objectFit="contain"
                alt="" 
              />
            </div>
            {/* <img 
              ref={refValueImg}
              className={`page-about__section-us__container__section__img image ${fadeIn(inViewValueImg)}`}
              src={ blockchainSvg } 
              alt="" 
            /> */}
            <div
              ref={refValueContent} 
              className={`page-about__section-us__container__section__content content ${fadeIn(inViewValueContent)}`}
            >
              <h1 className="title">Our Values</h1>
              <p className="text">
                Include levels the playing field for blockchain wealth creation. When more people can create for Web3, we will have a more inclusive, diverse and ultimately, a more successful blockchain ecosystem. 
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* HIGHTLIGHT */}
      <section className="page-about__section-highlight">
        <div className="page-about__section-highlight__container">
            {hightLights.map((highLight, index) => (
              <Box 
                key={highLight.title}
                title={highLight.title} 
                text={highLight.text} 
                index={index + 1}
              />
            ))}
          </div>
      </section>

      <section className="page-about__section-users">
        <div className="page-about__section-users__container">
          <div className={`img-container--desktop ${fadeIn(inViewUserImg)}`} ref={refUserImg}>   
            <StaticImage 
              className={`page-about__section-users__container__img`}
              src={ peopleImg } 
              objectFit="contain"
              layout="fixed"
              alt="" 
            />
          </div>

          {/* <img
            ref={refUserImg}
            className={`page-about__section-users__container__img ${fadeIn(inViewUserImg)}`}
            src={ peopleSvg } 
            alt="" 
          /> */}
          <div className={`img-container--mobile ${fadeIn(inViewUserImgMobile)}`} ref={refUserImgMobile} >   
            <StaticImage 
              className={`page-about__section-users__container__img--mobile`}
              src={chain090Img } 
              objectFit="contain"
              layout="fixed"
              alt="" 
            />
          </div>
          {/* <img
            ref={refUserImgMobile} 
            className={`page-about__section-users__container__img--mobile ${fadeIn(inViewUserImgMobile)}`} 
            src={ chain090Svg }
            alt="" 
          /> */}
          <div className="page-about__section-users__container__content">
            {users.map((user, index) => 
              <Box
                key={user.title}
                title={user.title} 
                text={user.text} 
                index={index + 1}
              />)}
          </div>
        </div>
      </section>
      <div className="page-about__space-background"></div>
    </Layout>
  )
}

export default AboutPage